import React from "react";
import { BiReceipt, BiTask } from "react-icons/bi";
import { BsPerson } from "react-icons/bs";
import { FaHome } from "react-icons/fa";
import { FiSettings } from "react-icons/fi";
import { MdSettings } from "react-icons/md";
import { NavLink, useRoutes } from "react-router-dom";
import styled from "styled-components";
import { useGlobalContext } from "../context/UserContext";

const FloatingNav = () => {
  const { isAdmin, isAgent } = useGlobalContext();
  const navList = [
    { name: "home", link: "/profile", icon: <FaHome size={36} /> },
    {
      name: "transactions",
      link: "/profile/transactions",
      icon: <BiReceipt size={36} />,
    },
    // { name: "Task", link: "task", icon: <BiTask size={36} /> },
    {
      name: "me",
      link: isAdmin ? "/admin/users" : "/profile/user",
      icon: <BsPerson size={36} />,
    },
    {
      name: "settings",
      link: isAdmin || isAgent ? "/profile/updatePrice" : "/profile/settings",
      icon: <MdSettings size={36} />,
    },
  ];
  return (
    <Wrapper className="md:rounded-2xl">
      <div className="   w-[100%] flex justify-evenly cursor-pointer">
        {navList.map((e) => (
          <NavLink
            to={e.link}
            key={e.name}
            className={({ isActive }) =>
              `p-1 my-2 relative
              ${isActive ? " rounded-full bg-white" : " text-white"}`
            }
          >
            {e.icon}
            {/* <div className="absolute  top-0 -right-5  text-green-700 p-[0.1rem] bg-white rounded-full ">
              0
            </div> */}
          </NavLink>
        ))}
      </div>
    </Wrapper>
  );
};

export default FloatingNav;
const Wrapper = styled.nav`
  position: fixed;
  bottom: 0;
  left: 0;
  right: 0;
  margin: 0 auto;
  z-index: 99;
  max-width: 500px;
  backdrop-filter: blur(2px);
  display: flex;
  justify-content: space-around;
  align-items: center;
  /* border-radius: 1rem; */
  /* border-radius: 100px; */
  transition: var(--transition);
  background-color: rgba(0, 0, 0, 0.6);
  .icon {
    transition: var(--transition);
    font-size: 1.5rem;
    border-radius: 3em;
    margin: 0.5rem 0;
    width: 40px;
    height: 40px;
    border-radius: 50%;
    color: #fff;
    display: flex;
  }
  .active {
    transition: var(--transition);
    background-color: var(--primary-900);
    color: var(--white);
  }
  svg {
    margin: auto;
  }
`;
