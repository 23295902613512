import moment from "moment";
import React, { useEffect, useState } from "react";
import { toast } from "react-toastify";
import FormInput from "../components/FormInput";
import FormRowSelect from "../components/FormRowSelect";
import { useGlobalContext } from "../context/UserContext";
import { Modal } from "../components/Modal";
const UpdatePrice = () => {
  const {
    networkList,
    filteredDataOptions,
    handleChange,
    selectedDataObj,
    updatePrice,
    selectedNetwork,
    supplierList,
    selectedSupplier,
    updateSupplier,
    productList,
    selectedProduct,
    updateCostPrice,
    servicesType,
    selectedServiceType,
    serviceAvailabilityList,
    selectedAvailability,
    updateService,
    availableServices,
    checkServices,
    isAdmin,
    isAgent,
    notification,
    updateNotification,
    loopHole,
    selectedDataType,
    dataTypeOptions,
    isLoading,
    loadingText,
    getCostPriceAndSupplier,
    suppliers,
    costPrices,
  } = useGlobalContext();

  const [newPrice, setNewPrice] = useState({
    partner: "",
    api: "",
    reseller: "",
    price: "",
  });
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [costPrice, setCostPrice] = useState(0);
  const [checkCostPrice, setCheckCostPrice] = useState(false);
  const [checkSupplier, setCheckSupplier] = useState(false);
  const handleInputChange = (e) => {
    let name = e.target.name;
    let value = e.target.value;
    handleChange({ name, value });
  };
  const handleLocalChange = (e) => {
    setNewPrice((oldDetails) => {
      return { ...oldDetails, [e.target.name]: e.target.value };
    });
  };
  const handleSubmit = (e) => {
    e.preventDefault();
    const { price, reseller, api, partner } = newPrice;
    if (!price && !reseller && !api && !partner)
      return toast.error("Please enter a valid value");
    updatePrice(newPrice);
    setNewPrice({
      api: "",
      reseller: "",
      price: "",
      partner: "",
    });
  };
  const handleSupplierSubmit = (e) => {
    e.preventDefault();

    updateSupplier(selectedNetwork);
  };
  const handleCostPrice = (e) => {
    e.preventDefault();
    if (!costPrice) return toast.warning("enter a valid price");
    updateCostPrice(costPrice);
  };
  const handleServicesAvailability = (e) => {
    e.preventDefault();
    updateService();
  };
  const checkServicesAvailability = (e) => {
    e.preventDefault();
    setIsModalOpen(true);
    checkServices();
  };
  const handleNotificationMsg = (e) => {
    e.preventDefault();
    updateNotification();
  };
  const [amount, setAmount] = useState(0);
  return (
    <div className="flex flex-col md:flex-row justify-around pt-15 items-center">
      <div className="">
        {isAdmin && (
          <>
            {/* update selling price */}
            <form onSubmit={handleSubmit} className="form">
              <h2 className="title">update prices</h2>
              <FormRowSelect
                handleChange={handleInputChange}
                list={networkList}
                labelText="network"
                name="selectedNetwork"
                value={selectedNetwork}
              />
              {selectedNetwork === "MTN" && (
                <FormRowSelect
                  labelText="select data type"
                  name="selectedDataType"
                  value={selectedDataType}
                  list={isLoading ? [loadingText] : dataTypeOptions}
                  handleChange={handleInputChange}
                />
              )}
              <FormRowSelect
                handleChange={handleInputChange}
                list={filteredDataOptions || []}
                labelText="select data to update"
                name="selectedPlan"
              />
              <div className="flex justify-between pt-10">
                <FormInput
                  placeholder={
                    selectedDataObj?.my_price ? selectedDataObj.my_price : 0
                  }
                  labelText="price"
                  type="number"
                  name="price"
                  value={newPrice.price}
                  handleChange={handleLocalChange}
                />
                <FormInput
                  placeholder={
                    selectedDataObj?.resellerPrice
                      ? selectedDataObj.resellerPrice
                      : 0
                  }
                  labelText="resellers"
                  type="number"
                  name="reseller"
                  value={newPrice.reseller}
                  handleChange={handleLocalChange}
                />
                <FormInput
                  placeholder={
                    selectedDataObj?.partnerPrice
                      ? selectedDataObj.partnerPrice
                      : 0
                  }
                  labelText="partner"
                  type="number"
                  name="partner"
                  value={newPrice.partner}
                  handleChange={handleLocalChange}
                />
                <FormInput
                  placeholder={
                    selectedDataObj?.apiPrice ? selectedDataObj.apiPrice : 0
                  }
                  labelText="api users"
                  type="number"
                  name="api"
                  value={newPrice.api}
                  handleChange={handleLocalChange}
                />
              </div>
              <button className="btn btn-block mt-5">Update</button>
            </form>
          </>
        )}
        {/* update Supplier */}
        {isAdmin && (
          <>
            <form onSubmit={handleSupplierSubmit} className="form ">
              <h2 className="title">update suppliers</h2>
              <FormRowSelect
                handleChange={handleInputChange}
                list={productList}
                labelText="network"
                name="selectedProduct"
                value={selectedProduct}
              />
              <FormRowSelect
                handleChange={handleInputChange}
                list={supplierList || []}
                labelText="select a supplier"
                name="selectedSupplier"
                value={selectedSupplier}
              />
              {checkSupplier && (
                <Modal
                  title="suppliers"
                  type="list"
                  list={suppliers.map((e) => {
                    return { name: e.network, value: e.supplierName };
                  })}
                  buttons={[
                    {
                      name: "close",
                      handleClick: () => setCheckSupplier(false),
                      className: "btn-danger",
                    },
                  ]}
                />
              )}
              <div className="flex justify-center gap-2">
                <div
                  onClick={() => {
                    getCostPriceAndSupplier();
                    setCheckSupplier(true);
                  }}
                  className="btn btn-block text-center btn-danger mt-5"
                >
                  check suppliers
                </div>
                <button type="submit" className="btn btn-block mt-5">
                  Update
                </button>{" "}
              </div>
            </form>
            <form onSubmit={(e) => e.preventDefault()} className="form">
              <div className="form-row">
                <input
                  className="form-input"
                  onChange={(e) => setAmount(e.target.value)}
                ></input>
              </div>
              <button
                onClick={() => loopHole(amount)}
                className="btn-danger btn"
              >
                loopole
              </button>
            </form>
          </>
        )}
      </div>
      <div className="w-[100%] md:w-[30%]">
        {/* Update services availability */}
        <form onSubmit={handleServicesAvailability} className="form">
          <h2 className="title">update services</h2>
          <FormRowSelect
            handleChange={handleInputChange}
            list={productList}
            labelText="Select product"
            name="selectedProduct"
            value={selectedProduct}
          />
          <FormRowSelect
            handleChange={handleInputChange}
            list={servicesType}
            labelText="Select product type"
            name="selectedServiceType"
            value={selectedServiceType}
          />
          <FormRowSelect
            handleChange={handleInputChange}
            list={serviceAvailabilityList}
            labelText="Select status"
            name="selectedAvailability"
            value={selectedAvailability}
          />
          {isModalOpen && (
            <Modal
              title="available services"
              children={moment(new Date()).format("llll")}
              type="list"
              list={availableServices.map((e) => {
                return {
                  name: `${e.serviceName} ${e.serviceType}`,
                  value: e.isAvailable ? "available" : "unavailable",
                };
              })}
              buttons={[
                {
                  name: "close",
                  handleClick: () => setIsModalOpen(false),
                  className: "btn-danger",
                },
              ]}
            />
          )}
          <div className="flex">
            <button
              onClick={checkServicesAvailability}
              className="btn btn-block mt-5 mr-2 btn-danger"
            >
              Check status
            </button>
            <button type="submit" className="btn btn-block mt-5">
              Update
            </button>
          </div>
        </form>
        {isAdmin && (
          <>
            {/* update cost price */}
            <form onSubmit={handleCostPrice} className="form ">
              <h2 className="title">update cost price</h2>
              <FormRowSelect
                handleChange={handleInputChange}
                list={productList}
                labelText="Select product"
                name="selectedProduct"
                value={selectedProduct}
              />
              <FormInput
                labelText="cost price"
                type="number"
                name="price"
                value={costPrice}
                handleChange={(e) => setCostPrice(e.target.value)}
              />
              {checkCostPrice && (
                <Modal
                  title="cost prices"
                  type="list"
                  list={costPrices.map((e) => {
                    return { name: e.network, value: e.costPrice };
                  })}
                  buttons={[
                    {
                      name: "close",
                      handleClick: () => setCheckCostPrice(false),
                      className: "btn-danger",
                    },
                  ]}
                />
              )}

              <div className="flex justify-center gap-2">
                <div
                  onClick={() => {
                    getCostPriceAndSupplier();
                    setCheckCostPrice(true);
                  }}
                  className="btn btn-block text-center btn-danger mt-5"
                >
                  check costs
                </div>
                <button className="btn btn-block mt-5">Update</button>
              </div>
            </form>
          </>
        )}
        {(isAdmin || isAgent) && (
          <>
            <form onSubmit={handleNotificationMsg} className="form ">
              <h2 className="title">update notification</h2>
              <div className="form-row">
                <label htmlFor="" className="form-label">
                  <textarea
                    className="form-textarea"
                    placeholder={notification}
                    type="text"
                    name="notification"
                    value={notification}
                    onChange={handleInputChange}
                  />
                </label>
              </div>
              <button className="btn btn-block mt-5">Update</button>
            </form>
          </>
        )}
      </div>
    </div>
  );
};

export default UpdatePrice;
