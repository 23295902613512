import React from "react";

const Tasks = () => {
  return (
    <div>
      <h1 className="title underline">Tasks</h1>
      <p className="text-center">Earn more by doing tasks</p>
      <div className="fixed bottom-2/4 left-1/4 -rotate-45">
        <p className="text-red-400 block text-7xl md:text-8xl w-[100%]">
          Coming soon!!!
        </p>
      </div>
    </div>
  );
};

export default Tasks;
