import React, { useState } from "react";
import TransactionDetails from "./TransactionDetails";
import moment from "moment";
import { useGlobalContext } from "../context/UserContext";

function EachTransaction(props) {
  const { isSelecting, transactions } = props;
  const { isAdmin, isAgent, handleChange, selectedIds } = useGlobalContext();
  const [showDetails, setShowDetails] = useState(false);
  const [transactionDetails, setTransactionDetails] = useState({});
  const [selectedId, setSelectedId] = useState([]);

  const onSelect = ({ id, value }) => {
    if (isSelecting) {
      if (value) {
        handleChange({ name: "selectedIds", value: [...selectedIds, id] });
      } else {
        handleChange({
          name: "selectedIds",
          value: selectedIds.filter((e) => e !== id),
        });
      }
      return;
    } else setShowDetails(!showDetails);
  };

  // const selectAll = () =>
  //   handleChange({
  //     name: "selectedIds",
  //     value: transactions.map((e) => e._id),
  //   });

  return (
    <>
      {showDetails && (
        <TransactionDetails
          details={transactionDetails}
          close={() => onSelect({ id: "", value: "" })}
        />
      )}

      <div className="md:max-h-[70vh] overflow-y-scroll  ">
        <table
          id="t01"
          className="table-auto hover:table-fixed m-auto border-[var(--primary-200)] border-[2.5px] "
        >
          <thead className="bg-[var(--primary-500)] text-white ">
            <tr>
              {isSelecting && isAdmin && <th>Select </th>}
              <th>Network</th>
              {isAdmin && <th>Supplier</th>}
              <th>Status</th>
              {isAdmin && !isAgent && <th>Profit</th>}
              {(isAdmin || isAgent) && <th>User</th>}
              <th>Amount</th>
              <th>Number</th>
              <th>Date</th>
              <th>Balance Before</th>
              <th>Balance After</th>
            </tr>
          </thead>
          {transactions.length > 0 ? (
            <tbody className="">
              {transactions.map((e) => {
                const balanceIncrease = e.balance_After > e.balance_Before;
                return (
                  <tr
                    onClick={() => {
                      onSelect({ id: "", value: "" });
                      setTransactionDetails(e);
                    }}
                    key={e._id}
                    className={`text-xs font-bold even:bg-[#eee] hover:bg-[#eee]  whitespace-nowrap text-left border-b-2  ${
                      e.trans_Status !== "success" && " text-red-500"
                    } ${e.trans_Status == "processing" && " text-[#8E4B10]"} `}
                  >
                    {isSelecting && isAdmin && (
                      <td className="pl-3">
                        <input
                          type="checkbox"
                          // checked={selectedId.includes(e._id)}
                          onChange={(event) =>
                            onSelect({ id: e._id, value: event.target.checked })
                          }
                        />
                      </td>
                    )}
                    <td className="text-xs py-4 pl-4 capitalize">
                      {e.trans_Network}
                    </td>
                    {isAdmin && (
                      <td className="text-xs py-4 capitalize">
                        {e.trans_supplier}
                      </td>
                    )}
                    <td
                      className={
                        e.trans_Status === "success"
                          ? "text-green-500"
                          : "text-red-500"
                      }
                    >
                      {e.trans_Status}
                    </td>
                    {isAdmin && !isAgent && (
                      <td
                        className={
                          e.trans_profit > 0 ? "text-green-500" : "text-red-500"
                        }
                      >
                        ₦{e.trans_profit ? e.trans_profit.toFixed(2) : 0.0}
                      </td>
                    )}
                    {(isAdmin || isAgent) && (
                      <td className="text-xs">{e.trans_UserName}</td>
                    )}

                    <td
                      className={`${
                        balanceIncrease ? "text-green-500" : "text-red-500"
                      }`}
                    >{`₦ ${balanceIncrease ? "+" : "-"}${e.trans_amount}`}</td>
                    <td>{e.phone_number}</td>
                    <td className="">{moment(e.createdAt).calendar()}</td>
                    {/* <td>{moment(e.createdAt).format("h:mm a MMM Do ")}</td> */}
                    <td>{`₦${e.balance_Before.toFixed(2)}`}</td>
                    <td>{`₦${e.balance_After.toFixed(2)}`}</td>
                  </tr>
                );
              })}
            </tbody>
          ) : (
            <caption>No transactions found</caption>
          )}
        </table>
      </div>
    </>
  );
}

export default EachTransaction;
