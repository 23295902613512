export const HANDLE_CHANGE = "HANDLE_CHANGE";

export const HANDLE_DATA_OPTION_CHANGE = "HANDLE_DATA_OPTION_CHANGE";

export const START_LOADING = "START_LOADING";
export const STOP_LOADING = "STOP_LOADING";

export const LOGOUT_USER = "LOGOUT_USER";

export const FETCH_ADMIN_BEGIN = "FETCH_ADMIN_BEGIN";
export const FETCH_ADMIN_SUCCESS = "FETCH_ADMIN_SUCCESS";
export const FETCH_ADMIN_ERROR = "FETCH_ADMIN_ERROR";

export const SETUP_USER_BEGIN = "SETUP_USER_BEGIN";
export const SETUP_USER_SUCCESS = "SETUP_USER_SUCCESS";
export const SETUP_USER_ERROR = "SETUP_USER_ERROR";

export const UPDATE_SERVICE_BEGIN = "UPDATE_SERVICE_BEGIN";
export const UPDATE_SERVICE_SUCCESS = "UPDATE_SERVICE_SUCCESS";
export const UPDATE_SERVICE_ERROR = "UPDATE_SERVICE_ERROR";

export const UPDATE_PRICE_BEGIN = "UPDATE_PRICE_BEGIN";
export const UPDATE_PRICE_SUCCESS = "UPDATE_PRICE_SUCCESS";
export const UPDATE_PRICE_ERROR = "UPDATE_PRICE_ERROR";

export const BUY_DATA_BEGIN = "BUY_DATA_BEGIN";
export const BUY_DATA_SUCCESS = "BUY_DATA_SUCCESS";
export const BUY_DATA_ERROR = "BUY_DATA_ERROR";

export const BUY_AIRTIME_BEGIN = "BUY_AIRTIME_BEGIN";
export const BUY_AIRTIME_SUCCESS = "BUY_AIRTIME_SUCCESS";
export const BUY_AIRTIME_ERROR = "BUY_AIRTIME_ERROR";

export const UPGRADE_USER_BEGIN = "UPGRADE_USER_BEGIN";
export const UPGRADE_USER_SUCCESS = "UPGRADE_USER_SUCCESS";
export const UPGRADE_USER_ERROR = "UPGRADE_USER_ERROR";

export const REQUEST_PASSWORD_RESET_SUCCESS = "REQUEST_PASSWORD_RESET_SUCCESS";

export const RESET_PASSWORD_SUCCESS = "RESET_PASSWORD_SUCCESS";

export const CHANGE_PAGE = "CHANGE_PAGE";

export const FILTER_TRANSACTION_BEGIN = "FILTER_TRANSACTION_BEGIN";
export const FILTER_TRANSACTION_SUCCESS = "FILTER_TRANSACTION_SUCCESS";
export const FILTER_TRANSACTION_ERROR = "FILTER_TRANSACTION_ERROR";

export const CLEAR_FILTER = "CLEAR_FILTER";

export const VALIDATE_USER_SUCCESS = "VALIDATE_USER_SUCCESS";

export const TRANSFER_FUND_SUCCESS = "TRANSFER_FUND_SUCCESS";

export const CHANGE_PASSWORD_SUCCESS = "CHANGE_PASSWORD_SUCCESS";

export const GENERATE_COUPON_SUCCESS = "GENERATE_COUPON_SUCCESS";

export const SEND_MAIL_SUCCESS = "SEND_MAIL_SUCCESS";

export const VALIDATE_SUCCESS = "VALIDATE_SUCCESS";

export const BUY_ELECTRICITY_SUCCESS = "BUY_ELECTRICITY_SUCCESS";

export const SELECTED_DATA_CHANGE = "SELECTED_DATA_CHANGE";

export const FUND_WALLET_COUPON = "FUND_WALLET_COUPON";

export const INITIATE_PAYMENT_SUCCESS = "INITIATE_PAYMENT_SUCCESS";

export const UPDATE_SUPPLIER_SUCCESS = "UPDATE_SUPPLIER_SUCCESS";

export const FETCH_SUPPLIER_SUCCESS = "FETCH_SUPPLIER_SUCCESS";

export const CHECK_SERVICES_SUCCESS = "CHECK_SERVICES_SUCCESS";

export const FETCH_BANK_CODES_SUCCESS = "FETCH_BANK_CODES_SUCCESS";

export const WITHDRAWAL_SUCCESS = "WITHDRAWAL_SUCCESS";

export const ACCOUNT_NUMBER_VALIDATE_SUCCESS =
  "ACCOUNT_NUMBER_VALIDATE_SUCCESS";

export const GET_NOTIFICATION_SUCCESS = "GET_NOTIFICATION_SUCCESS";
export const CLEAR_NOTIFICATION = "CLEAR_NOTIFICATION";

export const ADD_CONTACT_SUCCESS = "ADD_CONTACT_SUCCESS";

export const FETCH_CONTACT_SUCCESS = "FETCH_CONTACT_SUCCESS";

export const FETCH_BENEFICIARY_SUCCESS = "FETCH_BENEFICIARY_SUCCESS";

export const DELETE_CONTACT_SUCCESS = "DELETE_CONTACT_SUCCESS";
export const FETCH_REFERRAL_LIST_SUCCESS = "FETCH_REFERRAL_LIST_SUCCESS";
export const CHANGE_DATA_TYPE_OPTION = "CHANGE_DATA_TYPE_OPTION";

export const FETCH_COST_AND_SUPPLIER_SUCCESS =
  "FETCH_COST_AND_SUPPLIER_SUCCESS";

export const CHECK_NETWORK_STATUS_START = "CHECK_NETWORK_STATUS_START";
export const CHECK_NETWORK_STATUS_SUCCESS = "CHECK_NETWORK_STATUS_SUCCESS";
export const CHECK_NETWORK_STATUS_ERROR = "CHECK_NETWORK_STATUS_ERROR";

export const SEND_OTP_SUCCESS = "SEND_OTP_SUCCESS";
export const VERIFY_OTP_SUCCESS = "VERIFY_OTP_SUCCESS";
export const TRANSFER_AIRTIME_SUCCESS = "TRANSFER_AIRTIME_SUCCESS";

export const CHECK_STAT_SUCCESS = "CHECK_STAT_SUCCESS";
